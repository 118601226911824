<script setup lang="ts">
import { defineProps, withDefaults, computed } from "vue";
import { type DataObjectProgress } from "o365.pwa.modules.client.steps.DataObjectProgress.ts";
import { SyncStatus } from "o365.pwa.modules.client.steps.StepSyncProgress.ts";

interface IProps {
	syncStepProgress: DataObjectProgress;
	currentStep: boolean;
}

const props = withDefaults(defineProps<IProps>(), {
	currentStep: false,
});

const percentage = (curr, max) => {
	return (100 * curr) / max;
};

const completedRecords = (ref, failed, toSync) => {
	if (ref + failed === toSync) {
		return true;
	}
	return false;
};
const hasStarted = computed(() => {
	if (props.syncStepProgress.startedGeneratingOfflineData || props.syncStepProgress.startedRetrievingRowCount) {
		return true;
	};
	return false;
});

const hasCompleted = computed(() => {
	return props.syncStepProgress.completedGeneratingOfflineData && props.syncStepProgress.completedRetrievingRowCount;
});

const hasErrors = computed(() => {
	return combinedErrors.value.length > 0;
});

const combinedErrors = computed(() => {
	return combineMessages(props.syncStepProgress.uiFriendlyMessages);
});


// Define the interface for the output
interface CombinedMessage {
	title: string;
	body: string;
	count: number;
}

function combineMessages(messages: Array<{ title: string; body: string; }>): CombinedMessage[] {
	const messageCounts: Record<string, number> = {};

	messages.forEach(msg => {
		const key = `${msg.title}_${msg.body}`;  // Create a unique key based on title and body
		messageCounts[key] = (messageCounts[key] || 0) + 1;
	});

	const combinedMessages: CombinedMessage[] = [];
	for (const [key, count] of Object.entries(messageCounts)) {
		const [title, body] = key.split('_');  // Split the key back to title and body
		combinedMessages.push({ title, body, count });
	}

	return combinedMessages;
}
</script>

<script lang="ts">
export default {
	name: "DataObjectProgressOffline",
};
</script>

<template>
	<div class="card shadow-sm mb-3 w-100">
		<div class="card-header d-flex justify-content-between">
			<div class="d-flex flex-column">
				<!-- Definition -->
				<div class="fw-bold">
					<span>{{ props.syncStepProgress.title }}</span>
				</div>
				<!-- TITLE -->
				<div class="small-subtitle">
					Data Object
				</div>
			</div>
			<!-- STATUS -->
			<div class="d-flex justify-content-center">
				<template v-if="props.syncStepProgress._syncStatus === 0">
					<span class="text-black d-flex align-items-center gap-1" v-if="!hasStarted">
						<i class="bi bi-pause-circle-fill text-black"></i> Waiting to start
					</span>
				</template>
				<template v-if="props.syncStepProgress._syncStatus === 7">
					<span class="text-success d-flex align-items-center gap-1" v-if="hasCompleted">
						<i class="bi bi-check-circle-fill text-success"></i> Completed
					</span>
				</template>
				<template v-if="props.syncStepProgress._syncStatus === 9">
					<span class="text-danger d-flex align-items-center gap-1" v-if="hasErrors">
						<i class="bi bi-exclamation-circle-fill text-danger"></i> Error completing
					</span>
				</template>
				<template v-if="props.syncStepProgress._syncStatus === 10">
					<span class="text-danger d-flex align-items-center gap-1">
						<i class="bi bi-x-octagon-fill text-danger"></i> Cancelled
					</span>
				</template>
			</div>
		</div>
		<div class="card-body">
			<div>
				<template v-if="props.syncStepProgress._syncStatus === SyncStatus.PreSyncCleanupStarted">
					<div class="d-flex align-items-center justify-content-between gap-1">
						<div>Running record pre-cleanup...</div>
						<div class="spinner-border spinner-border-sm" role="status">
							<span class="visually-hidden">Loading...</span>
						</div>
					</div>
				</template>
				<template v-if="props.syncStepProgress._syncStatus > SyncStatus.PreSyncCleanupStarted">
					<div class="d-flex align-items-center justify-content-between gap-1 text-success-subtle">
						<div>Completed record pre-cleanup.</div>
						<div><i class="bi bi-check-circle-fill text-success"></i></div>
					</div>
				</template>
				<div v-if="props.syncStepProgress.startedGeneratingOfflineData && (!hasCompleted && !hasErrors)"
					class="d-flex align-items-center justify-content-between gap-1">
					<div>Generating offline data...</div>
					<div class="spinner-border spinner-border-sm" role="status">
						<span class="visually-hidden">Loading...</span>
					</div>
				</div>
				<div v-if="props.syncStepProgress.errorsGeneratingOfflineData"
					class="d-flex align-items-center justify-content-between gap-1 text-danger-subtle">
					<div>Errors generating offline data.</div>
					<div>
						<i class="bi bi-exclamation-circle-fill text-danger"></i>
					</div>
				</div>
				<div v-if="props.syncStepProgress.completedGeneratingOfflineData && !props.syncStepProgress.errorsGeneratingOfflineData"
					class="d-flex align-items-center justify-content-between gap-1">
					<div>Completed generating offline data.</div>
					<div><i class="bi bi-check-circle-fill text-success"></i></div>
				</div>
				<div v-if="props.syncStepProgress.startedRetrievingRowCount && !props.syncStepProgress.completedRetrievingRowCount && !props.syncStepProgress.errorsRetrievingRowCount"
					class="d-flex align-items-center justify-content-between gap-1">
					<div>Started retrieving row count...</div>
					<div class="spinner-border spinner-border-sm" role="status">
						<span class="visually-hidden">Loading...</span>
					</div>
				</div>
				<div v-if="props.syncStepProgress.errorsRetrievingRowCount && !props.syncStepProgress.completedRetrievingRowCount"
					class="d-flex align-items-center justify-content-between gap-1 text-danger-subtle">
					<div>Errors retrieving row count.</div>
					<div>
						<i class="bi bi-exclamation-circle-fill text-danger">
						</i>
					</div>
				</div>
				<div v-if="props.syncStepProgress.completedRetrievingRowCount"
					class="d-flex align-items-center justify-content-between gap-1">
					<div>Completed retrieving row count.</div>
					<div><i class="bi bi-check-circle-fill text-success"></i></div>
				</div>
				<div v-if="props.syncStepProgress.recordsToSync > 0" class="gap-1 my-2">
					<div class="d-flex w-100 justify-content-between">
						<div class="d-flex gap-1 align-items-baseline">Downloaded <div style="font-size: 12px;"
								class="text-info">Records</div>
						</div>
						<div class="d-flex gap-1">
							<span>Rows: </span>
							<span>{{ props.syncStepProgress.recordsCompleted }}</span>
							<span> / </span>
							<span>{{ props.syncStepProgress.recordsToSync / 2 }}</span>
						</div>
					</div>


					<div class="progress-stacked my-1" :style="{
						height: completedRecords(
							props.syncStepProgress.recordsStarted,
							props.syncStepProgress.recordsFailed,
							props.syncStepProgress.recordsToSync / 2
						)
							? '5px'
							: '15px',
					}">
						<!-- Downloaded -->
						<div ref="progressDownloaded" class="progress" role="progressbar" aria-valuemin="0"
							aria-valuemax="100"
							:style="{ width: percentage(props.syncStepProgress.recordsStarted, props.syncStepProgress.recordsToSync / 2) + '%' }">
							<div v-if="
								completedRecords(props.syncStepProgress.recordsStarted, props.syncStepProgress.recordsFailed, props.syncStepProgress.recordsToSync / 2)
							" class="progress-bar bg-primary"></div>
							<div v-else class="progress-bar progress-bar-striped progress-bar-animated bg-primary"></div>
						</div>
						<!-- Failed -->
						<div class="progress" role="progressbar"
							:aria-valuenow="percentage(props.syncStepProgress.recordsFailed, props.syncStepProgress.recordsToSync / 2)"
							aria-valuemin="0" aria-valuemax="100"
							:style="{ width: percentage(props.syncStepProgress.recordsFailed, props.syncStepProgress.recordsToSync / 2) + '%' }">
							<div v-if="
								completedRecords(props.syncStepProgress.recordsCompleted, props.syncStepProgress.recordsFailed, props.syncStepProgress.recordsToSync / 2)
							" class="progress-bar bg-danger"></div>
							<div v-else class="progress-bar progress-bar-striped progress-bar-animated bg-danger"></div>
						</div>
					</div>

					<div class="d-flex w-100 justify-content-between">
						<div class="d-flex gap-1 align-items-baseline">Stored <div style="font-size: 12px;"
								class="text-info">Records</div>
						</div>
						<div class="d-flex gap-1">
							<span>Rows: </span>
							<span>{{ props.syncStepProgress.recordsCompleted }}</span>
							<span> / </span>
							<span>{{ props.syncStepProgress.recordsToSync / 2 }}</span>
						</div>
					</div>

					<div class="progress-stacked my-1" :style="{
						height: completedRecords(
							props.syncStepProgress.recordsCompleted,
							props.syncStepProgress.recordsFailed,
							props.syncStepProgress.recordsToSync / 2
						)
							? '5px'
							: '15px',
					}">
						<!-- Stored -->
						<div class="progress" role="progressbar"
							:aria-valuenow="percentage(props.syncStepProgress.recordsCompleted, props.syncStepProgress.recordsToSync / 2)"
							aria-valuemin="0" aria-valuemax="100"
							:style="{ width: percentage(props.syncStepProgress.recordsCompleted, props.syncStepProgress.recordsToSync / 2) + '%' }">
							<div v-if="
								completedRecords(
									props.syncStepProgress.recordsCompleted,
									props.syncStepProgress.recordsFailed,
									props.syncStepProgress.recordsToSync / 2
								)
							" class="progress-bar bg-success"></div>
							<div v-else class="progress-bar progress-bar-striped progress-bar-animated bg-success"></div>
						</div>
						<!-- Failed -->
						<div class="progress" role="progressbar"
							:aria-valuenow="percentage(props.syncStepProgress.recordsFailed, props.syncStepProgress.recordsToSync / 2)"
							aria-valuemin="0" aria-valuemax="100"
							:style="{ width: percentage(props.syncStepProgress.recordsFailed, props.syncStepProgress.recordsToSync / 2) + '%' }">
							<div v-if="
								completedRecords(
									props.syncStepProgress.recordsCompleted,
									props.syncStepProgress.recordsFailed,
									props.syncStepProgress.recordsToSync / 2
								)
							" class="progress-bar bg-danger"></div>
							<div v-else class="progress-bar progress-bar-striped progress-bar-animated bg-danger"></div>
						</div>
					</div>
					<template
						v-if="props.syncStepProgress.startedRetrievingFiles || props.syncStepProgress.completedRetrievingFiles">
						<div class="d-flex w-100 justify-content-between">
							<div class="d-flex gap-1 align-items-baseline">Fetched & Stored <div style="font-size: 12px;"
									class="text-info">Files</div>
							</div>
							<div class="d-flex gap-1">
								<span>Files: </span>
								<span>{{ props.syncStepProgress.filesCompleted }}</span>
								<span> / </span>
								<span>{{ props.syncStepProgress.filesToSync }}</span>
							</div>
						</div>

						<div class="progress-stacked my-1" :style="{
							height: props.syncStepProgress.completedRetrievingFiles
								? '5px'
								: '15px',
						}">
							<!-- Stored -->
							<div class="progress" role="progressbar"
								:aria-valuenow="percentage(props.syncStepProgress.filesCompleted, props.syncStepProgress.filesToSync)"
								aria-valuemin="0" aria-valuemax="100"
								:style="{ width: percentage(props.syncStepProgress.filesCompleted, props.syncStepProgress.filesToSync) + '%' }">
								<div v-if="props.syncStepProgress.completedRetrievingFiles" class="progress-bar bg-purple">
								</div>
								<div v-else class="progress-bar progress-bar-striped progress-bar-animated bg-purple"></div>
							</div>
							<!-- Failed -->
							<div class="progress" role="progressbar"
								:aria-valuenow="percentage(props.syncStepProgress.filesFailed, props.syncStepProgress.filesToSync)"
								aria-valuemin="0" aria-valuemax="100"
								:style="{ width: percentage(props.syncStepProgress.filesFailed, props.syncStepProgress.filesToSync) + '%' }">
								<div v-if="props.syncStepProgress.completedRetrievingFiles" class="progress-bar bg-danger">
								</div>
								<div v-else class="progress-bar progress-bar-striped progress-bar-animated bg-danger"></div>
							</div>
						</div>
					</template>
				</div>
				<template v-if="hasErrors">
					<a class="text-danger fs-6 text-decoration-none" data-bs-toggle="collapse"
						:href="'#' + props.syncStepProgress.title.replaceAll(' ', '')" role="button" aria-expanded="false"
						aria-controls="collapseExample">
						Show errors ({{ combinedErrors.length }})
					</a>
					<div class="collapse" :id="props.syncStepProgress.title.replaceAll(' ', '')">
						<div class="card card-body d-flex bg-danger-subtle w-100">
							<ul class="list-group w-100">
								<li v-for="uiFriendlyMessage in combinedErrors"
									class="list-group-item list-group-item-danger d-flex flex-column">
									<span
										class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
										{{ uiFriendlyMessage.count }}
										<span class="visually-hidden">Error count</span>
									</span>
									<div class="fw-bold fs-6 text-nowrap">
										{{ uiFriendlyMessage.title }}
									</div>
									<div class="fs-6">
										{{ uiFriendlyMessage.body }}
									</div>
								</li>
							</ul>
						</div>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<style scoped>
.bg-purple {
	background-color: #9a80e9;
}

.small-subtitle {
	font-size: 12px;
}
</style>
